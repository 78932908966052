import React, { useEffect, useState } from "react";
import Spin from "antd/es/spin";
import notification from "antd/es/notification";
import ConfigProvider from "antd/es/config-provider";
import Modal from "antd/es/modal";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import { Model, setLicenseKey } from "survey-core";
import { Survey } from "survey-react-ui";
import http from "../utils/http";
import "survey-core/defaultV2.min.css";
import helper from "../utils/helper";
import DetailSurveyComponent from "../components/DetailSurveyComponent";
import "survey-core/survey.i18n";

function Main(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [model, setModel] = useState(null);
  const [detail, setDetail] = useState(null);
  const [surveyName, setSurveyName] = useState("");
  const decryptData = helper.decrypt(
    window.atob(new URLSearchParams(window.location.search).get("data"))
  );
  const surveyId = new URLSearchParams(decryptData).get("survey_id");
  const tenantId = new URLSearchParams(decryptData).get("tenant_id");
  const hp = new URLSearchParams(decryptData).get("hp");
  const email = new URLSearchParams(decryptData).get("email");
  const uid = new URLSearchParams(decryptData).get("uid");
  const [lat, setLat] = React.useState(null);
  const [long, setLong] = React.useState(null);
  useEffect(() => {
    if (parseInt(process.env.REACT_APP_LOCATION) === 1) {
      const geolocationAPI = navigator.geolocation;
      if (!geolocationAPI) {
        notification.error({
          message: "Geolocation API is not available in your browser.",
          placement: "bottomRight",
          duration: 5,
        });
      } else {
        geolocationAPI.getCurrentPosition(
          (position) => {
            const { coords } = position;
            setLat(coords.latitude);
            setLong(coords.longitude);
          },
          (error) => {
            notification.error({
              message: "Something went wrong getting your position.",
              placement: "bottomRight",
              duration: 5,
            });
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setOpenBackdrop(true);
        return config;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          notification.error({
            message: error.response
              ? typeof error.response.data?.message === "string"
                ? error.response.data?.message
                : error.response.data?.message
                ? error.response.data?.message?.map((v) => v.message)?.join(",")
                : "Something wrong with our server, please try again later..."
              : "Something wrong with our server, please try again later...",
            placement: "bottomRight",
            duration: 5,
          });
        }
        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (response?.config?.method !== "get") {
          if (response?.data?.message) {
            notification.success({
              message: response.data.message,
              placement: "bottomRight",
              duration: 5,
            });
          }
        }
        setOpenBackdrop(false);
        return response;
      },
      (error) => {
        if (error) {
          setOpenBackdrop(false);
          if (error.response.data?.message === "The survey already completed") {
            setSurveyName(error.response.data?.survey);
            setOpenComplete("completed");
          } else if (
            error.response.data?.message === "The survey hasn't started yet"
          ) {
            setSurveyName(error.response.data?.survey);
            setOpenComplete("started");
          } else {
            notification.error({
              message: error.response
                ? typeof error.response.data?.message === "string"
                  ? error.response.data?.message
                  : error.response.data?.message
                  ? error.response.data?.message
                      ?.map((v) => v.message)
                      ?.join(",")
                  : "Something wrong with our server, please try again later..."
                : "Something wrong with our server, please try again later...",
              placement: "bottomRight",
              duration: 5,
            });
          }
        }
        return Promise.reject(error);
      }
    );

    if (!props.isLatestVersion) {
      props.emptyCacheStorage();
    }
  }, []);

  useEffect(() => {
    setLicenseKey(
      "M2U3MmIzNjItYzhmYi00YmNlLWJlNjMtNzFmNzAwMzljZGMzOzE9MjAyNS0wMS0xOCwyPTIwMjUtMDEtMTgsND0yMDI1LTAxLTE4"
    );
    getSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeviceName = () => {
    let device = "Unknown";
    const ua = {
      "Generic Linux": /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      "Chrome OS": /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map(
      (v) => navigator.userAgent.match(ua[v]) && (device = v)
    );
    return device;
  };

  const getBrowserName = () => {
    var test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "Microsoft Edge";
      case test(/trident/i):
        return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i):
        return "Mozilla Firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC Browser";
      case test(/samsungbrowser/i):
        return "Samsung Browser";
      case test(/chrome|chromium|crios/i):
        return "Google Chrome";
      case test(/safari/i):
        return "Apple Safari";
      default:
        return "Other";
    }
  };

  const getSurvey = () => {
    http
      .get(
        `${tenantId}/survey/survey/${surveyId}?${uid ? `uid=${uid}` : ""}${
          password ? `&password=${password}` : ""
        }`,
        {
          auth: {
            username: process.env.REACT_APP_AUTH_USERNAME,
            password: process.env.REACT_APP_AUTH_PASSWORD,
          },
          headers: {
            "x-tenant": tenantId,
          },
        }
      )
      .then((res) => {
        setPassword("");
        setIsPassword(false);
        if (res) {
          if (new URLSearchParams(window.location.search).get("data")) {
            const decryptData = helper.decrypt(
              window.atob(
                new URLSearchParams(window.location.search).get("data")
              )
            );
            const getName = new URLSearchParams(decryptData).get("name");
            if (getName) {
              const parseQuestioner = JSON.parse(res.data.questioner.questions);
              if (parseQuestioner?.title?.id) {
                const replaceName = parseQuestioner?.title?.id.replace(
                  "{name}",
                  getName
                );
                parseQuestioner.title.id = replaceName;
              } else {
                const replaceName = parseQuestioner?.title.replace(
                  "{name}",
                  getName
                );
                parseQuestioner.title = replaceName;
              }
              if (parseQuestioner?.description) {
                const replaceName = parseQuestioner?.description?.replace(
                  "{name}",
                  getName
                );
                parseQuestioner.description = replaceName;
              }
              const stringifyQuestioner = JSON.stringify(parseQuestioner);
              res.data.questioner.questions = stringifyQuestioner;
            }
          }
          setDetail(res.data);
        }
      })
      .catch((error) => {
        setPassword("");
        if (error.response?.data?.statusCode === 400) {
          setIsPassword(true);
        }
      });
  };

  console.log("detail", detail);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  const startSurvey = (lang) => {
    const survey = new Model(detail.questioner?.questions);
    if (detail) {
      if (lang) {
        addQueryParam("locale", lang);
        survey.locale = lang;
      }

      survey.onComplete.add((sender, options) => {
        http.post(
          `${tenantId}/survey/submitSurvey`,
          {
            survey_id: parseInt(surveyId),
            result: JSON.stringify(sender.data, null, 3),
            hp: hp,
            email: email,
            uid: uid,
            location: JSON.stringify({ lat: lat, lng: long }),
            device: getDeviceName(),
            browser: getBrowserName(),
          },
          {
            auth: {
              username: process.env.REACT_APP_AUTH_USERNAME,
              password: process.env.REACT_APP_AUTH_PASSWORD,
            },
            headers: {
              "x-tenant": tenantId,
            },
          }
        );
      });
      setModel(survey);
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3e3190",
          colorLink: "#3e3190",
          colorLinkHover: "#3e3190",
        },
      }}
    >
      <Spin spinning={openBackdrop} tip="Please wait..." className="h-full">
        {detail ? (
          model ? (
            <Survey model={model} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row
                gutter={[12, 12]}
                style={{ width: "100%" }}
                justify={"center"}
              >
                <Col xs={24} sm={24} md={24} lg={8}>
                  <DetailSurveyComponent
                    detail={detail}
                    startSurvey={startSurvey}
                  />
                </Col>
              </Row>
            </div>
          )
        ) : openComplete ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              bodyStyle={{ padding: 0 }}
              style={{
                width: 800,
                overflow: "hidden",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ width: 20, backgroundColor: "#3F3192" }}></div>
                <img
                  style={{ padding: 40 }}
                  src={
                    process.env.REACT_APP_ASSET_URL +
                    "/images/survey_completed.svg"
                  }
                  alt="images"
                />
                <div style={{ padding: 40 }}>
                  <h1>
                    {surveyName}{" "}
                    {openComplete === "completed"
                      ? "already closed"
                      : "hasn't started yet"}
                  </h1>
                  {openComplete === "completed" ? (
                    <p style={{ color: "#a4a4a4" }}>
                      The survey did not accept any more responses due to
                      predetermined limitations
                    </p>
                  ) : null}
                </div>
              </div>
            </Card>
            <p style={{ fontSize: 10, color: "#a4a4a4" }}>
              Powered @ by Infomedia Nusantara
            </p>
          </div>
        ) : null}
      </Spin>
      <Modal
        title="Password Survey"
        open={isPassword}
        footer={[]}
        closable={false}
        destroyOnClose={false}
      >
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Please type password here..."
          style={{ marginBottom: 10 }}
        />
        <Button type="primary" onClick={() => getSurvey()}>
          Submit
        </Button>
      </Modal>
    </ConfigProvider>
  );
}

export default Main;
