import Button from "antd/es/button";
import Select from "antd/es/select";
import React from "react";
export default function DetailSurveyComponent({ detail, startSurvey }) {
  const [lang, setLang] = React.useState(false);
  const getListLang = () => {
    const objJson = JSON.parse(detail?.questioner?.questions);
    if (objJson) {
      if (objJson?.pages?.length > 0) {
        if (objJson?.pages[0]?.elements?.length > 0) {
          return typeof objJson?.pages[0]?.elements[0]?.title === "object"
            ? Object.keys(objJson?.pages[0]?.elements[0]?.title)
            : [];
        }
      }
    }
    return [];
  };

  const getDescription = () => {
    const objJson = JSON.parse(detail?.questioner?.questions);
    if (objJson) {
      return objJson?.description?.default
        ? objJson?.description?.default
        : objJson?.description;
    }
    return "-";
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          src={"/logo-dark.png"}
          alt="Logo Omnix"
          style={{ width: 200, objectFit: "contain", marginBottom: 10 }}
        />
        <div
          style={{
            fontSize: 17,
            color: "#9a9da2",
            fontWeight: "bold",
          }}
        >
          You received a survey from OMNIX
        </div>
      </div>
      <div
        style={{
          background: "#fff",
          borderRadius: 8,
          padding: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#acacac",
            marginBottom: 10,
          }}
        >
          Survey Title
        </div>
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#3e3190",
            borderBottom: "1px solid #e8e8e8",
            paddingBottom: 20,
          }}
        >
          {detail?.name}
        </div>

        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#acacac",
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          Description
        </div>
        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#000",
            borderBottom: "1px solid #e8e8e8",
            paddingBottom: 20,
          }}
        >
          {getDescription()}
        </div>

        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#acacac",
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          Total Question
        </div>
        <div
          style={{
            fontSize: 17,
            fontWeight: "bold",
            color: "#000",
            borderBottom: "1px solid #e8e8e8",
            paddingBottom: 20,
          }}
        >
          {detail?.questioner?.total_question || 0} Question
        </div>
        {getListLang()?.length > 0 ? (
          <>
            <div
              style={{
                fontSize: 17,
                fontWeight: "bold",
                color: "#acacac",
                marginBottom: 10,
                marginTop: 20,
              }}
            >
              Language
            </div>
            <Select
              value={lang ? lang : null}
              onChange={(e) => setLang(e)}
              style={{ width: "100%" }}
              placeholder="- Select Language -"
            >
              {getListLang().map((v, i) => (
                <Select.Option value={v} key={i}>
                  {v}
                </Select.Option>
              ))}
            </Select>
          </>
        ) : null}
      </div>
      <Button
        type="primary"
        block
        style={{ marginTop: 20 }}
        onClick={() => startSurvey(lang)}
      >
        Start Survey
      </Button>
      <div
        style={{
          fontSize: 17,
          color: "#9a9da2",
          fontWeight: "bold",
          marginTop: 20,
          textAlign: "center",
        }}
      >
        Powered &copy; by{" "}
        <a href="https://infomedia.co.id" target="_blank" rel="noreferrer">
          Infomedia Nusantara
        </a>
      </div>
    </div>
  );
}
